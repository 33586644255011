<template>
  <div class="mt-5">
    <div class="container-login">
      <div class="login-box">
        <h2>Login</h2>
        <hr />
        <form @submit.prevent="authenticate">
          <b-form-group
            id="input-group-user"
            label="Usuario"
            label-for="input-user"
          >
            <b-form-input
              id="input-user"
              type="email"
              required
              placeholder="Usuario"
              v-model="$v.user_form.email.$model"
              autocomplete="email"
              :state="validateState('email')"
              aria-describedby="input-user-name-feedback"
            ></b-form-input>
            <!-- @keyup.enter="authenticate" -->
            <b-form-invalid-feedback id="input-user-name-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            id="input-group-password"
            label="Clave"
            label-for="input-password"
          >
            <b-form-input
              id="input-password"
              v-model="user_form.password"
              type="password"
              required
              placeholder="Clave"
              autocomplete="current-password"
            ></b-form-input>
            <!-- @keyup.enter="authenticate" -->
          </b-form-group>
          <div class="forgot-password" @click="launchRecoverPasswordModal()">
            <!-- @click="$bvModal.show('modal-recovery-password')" -->
            ¿Olvidó su contraseña?
          </div>
          <!-- <b-button @click="authenticate" variant="primary">Entrar</b-button> -->
          <b-button type="submit" variant="primary">Entrar</b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Login",
  mixins: [validationMixin],
  data() {
    return {
      user_form: {
        email: "",
        password: "",
      },
      recovery_password_email: "",
      user: null,
    };
  },
  validations: {
    user_form: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
    recovery_password_email: {
      email,
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.user_form[key];
      return $dirty ? !$error : null;
    },
    validateRecoveryPassword() {
      const { $dirty, $error } = this.$v.recovery_password_email;
      return $dirty ? !$error : null;
    },
    launchRecoverPasswordModal() {
      // #TODO: Tomar el correo si está disponible y rellenar el input.
      // Será mejor crear un formulario para colocarlo dentro del modal.
      this.$swal
        .fire({
          title: "Ingresa tu correo electrónico para buscar tu cuenta.",
          input: "email",
          confirmButtonText: "Enviar",
          inputValue: this.user_form.email,
          reverseButtons: true,
          showLoaderOnConfirm: false,
          showCancelButton: true,
          preConfirm: (login) => {
            this.$swal.fire({
              title: "Cargando...",
              onBeforeOpen: () => {
                this.$swal.showLoading();
              },
            });
            return this.$restful
              .Get(`/authentication/send_reset_code/?email=${login}`)
              .then(() => {
                return { isConfirmed: true };
              })
              .catch((error) => {
                this.$swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
        })
        .then((result) => {
          if (result && result.value && result.value.isConfirmed) {
            this.$swal.fire({
              type: "success",
              title: `Revise su correo electrónico.`,
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
    },
    authenticate() {
      this.$v.user_form.$touch();
      if (this.$v.user_form.$anyError) {
        return;
      }
      this.$store.dispatch("login", {
        email: this.user_form.email,
        password: this.user_form.password,
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.login-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #f4f5f9;
}
.login-box {
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  max-width: 350px;
  width: 100%;
}
.forgot-password {
  color: #007bff;
  margin-bottom: 1.5rem;
  transition: color 0.3s ease;
}
.forgot-password:hover {
  color: #0057b1;
  text-decoration: underline;
  cursor: pointer;
}
.container-login {
  position: relative;
  margin: auto;
  width: 100%;
}
</style>
